.indexpage {
	.bg-dark-blue {
		background-color: var(--defaultcolor);
		color: var(--whitecolor);
	}

	.bg-dark-blue2 {
		color: var(--whitecolor);
	}

	.bg-primary {
		background-color: #d9edf7;
		color: var(--blackcolor);
	}

	.image-block-col {
		position: relative;

		@media (max-width:992px) {
			padding-top: 100%;
			width: 100%;
		}

		img {

			width: 100%;
			height: 100%;
			object-fit: cover;
			position: absolute;
			inset: 0;

		}
	}

	.content-half-bl {
		padding: 20px;

		@media (min-width:992px) {
			padding: 100px 60px;
		}
	}

	.bg-image-section {
		background-size: cover;
		background-attachment: fixed;
		background-position: top center;
		position: relative;

		&.image1 {
			background-image: url(RESOURCE/img/bg-image-section1.jpg);
		}

		&.image2 {
			background-image: url(RESOURCE/img/bg-image-section2.jpg);
		}

		&.bg-white {
			&:after {
				background-color: rgba(255, 255, 225, .5);
				content: "";
				width: 100%;
				height: 100%;
				top: 0;
				bottom: 0;
				position: absolute;
				z-index: 1;
			}
		}

		&.bg-dark {
			background-color: rgba(0, 51, 102, .6);
			/*&:after {
				
				content: "";
				width: 100%;
				height: 100%;
				top: 0;
				bottom: 0;
				position: absolute;
				z-index: 1;
			}*/

			color: var(--whitecolor);
		}

		.container {
			position: relative;
			z-index: 2;
		}
	}

	.feature-list {
		margin-top: 15px;
	}

	.feature-list li {
		padding-top: 8px;
		padding-bottom: 8px;
	}

	.feature-list li span {
		margin-right: 10px;
	}

	.colored-text {
		color: @introcolor;
	}

	/* COLORED BORDERS AND LINES */
	.colored-line-left,
	.colored-line-right,
	.colored-line {
		background: @introcolor;
	}

	.colored-line-left,
	.colored-line-right,
	.colored-line,
	.white-line,
	.grey-line {
		height: 2px;
		width: 80px;
		display: block;
	}

	.colored-line-left {
		float: left;
	}

	.colored-line-right {
		float: right;
	}

	.colored-line {
		margin: auto;
	}


	.small-text-medium {
		font-size: 14px;
		font-weight: 600;
	}

	.uppercase {
		text-transform: uppercase;
	}

	@media (max-width: 991px) {
		html {
			font-size: 90% !important;
		}

		.text-left,
		.text-right {
			text-align: center;
		}

		.colored-line-left {
			float: none;
			margin: auto;
		}


	}

}