:root {
	--introImg: url(RESOURCE/img/bg-banner-new.jpg);
	--introMobileImg: url(RESOURCE/img/banner-banner.Xc_770_770.jpg);
	--navbarbreak: 992px;
	--whitecolor: #fff;
	--darkbluecolor: #222d3b;
	--primarycolor: #daa520;
	--d-font: 'Montserrat', sans-serif;
	--greycolor: #acbac4;
	--secondarycolor: #DC143c;
	--secondarycolor2: #004789;
	--redcolor: #c90030;
	--reddarkcolor: #8f0000;
	--lightbluecolor: #6696bd;
	--infocolor: #BACE20;
	--light-grey: #f9f9f9;
	--defaultcolor: #003366;
	--blackcolor: #333;
	--darkgrey: #929292;
	--lightprimary: #f6edd8;

	/* calendar */
	--not_available: #dc143c;
	/*--disabled: #f6edd8;*/
	--disabled: #bfb397;
	--available: #f6edd8;
	--selected: #5d7ba0;
	--selection_range: #90b4e0;
	--selected_border: #fff;
	--text_color: #333;
}