footer {
	position: relative;
	border-top: 1px solid var(--greycolor);
	padding: 30px 0;
	background-color: var(--light-grey);

	&:before {
		background-color: rgba(255, 255, 255, .85);
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.widget-ft {
		min-height: 190px;
		margin: 15px 0;

		@media all and (max-width:767px) {
			min-height: inherit;
		}

		.widget-title {
			margin: 0 0 15px;
			color: var(--defaultcolor);
			font-size: 20px;
			font-weight: 700;
		}

		.list-block {
			li {
				padding-left: 16px;
				position: relative;
				padding-top: 6px;
				padding-bottom: 6px;
				border-bottom: 1px dotted rgba(228, 228, 228, 0.5);

				a {
					color: var(--darkgrey);

					&:hover {
						color: var(--defaultcolor);
					}
				}

				&:before {
					content: "\f105";

					color: var(--greycolor);
					font-family: fontawesome;
					position: absolute;
					left: 0;
				}
			}
		}

		.list-icon {
			li {
				color: var(--darkgrey);
				padding-top: 6px;
				padding-bottom: 6px;

				i {
					top: 10px;
				}

				a {
					color: var(--darkgrey);

					&:hover {
						color: var(--defaultcolor);
					}
				}
			}
		}

	}
}