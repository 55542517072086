/* montserrat-regular - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('https://r.v-office.com/fonts/montserrat-v25-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('https://r.v-office.com/fonts/montserrat-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://r.v-office.com/fonts/montserrat-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://r.v-office.com/fonts/montserrat-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
        url('https://r.v-office.com/fonts/montserrat-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('https://r.v-office.com/fonts/montserrat-v25-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
