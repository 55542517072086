/* montserrat-600 - latin */
@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('https://r.v-office.com/fonts/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('https://r.v-office.com/fonts/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('https://r.v-office.com/fonts/montserrat-v25-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('https://r.v-office.com/fonts/montserrat-v25-latin-600.woff') format('woff'), /* Modern Browsers */
        url('https://r.v-office.com/fonts/montserrat-v25-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('https://r.v-office.com/fonts/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
