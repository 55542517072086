@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

/*.list-view {
	.map-col {
		.affix {
			@media (min-width:993px) {
				position: fixed !important;
				top: 0;
				width: 100%;
				max-width: 480px;
			}
		}
	}
}
.map-view {
	.map-col {

		.affix,
		.affix-bottom {
			@media (min-width:993px) {
				position: relative !important;
				top: 0 !important;
				width: 100%;
				padding-right: 0;
				max-width: inherit;
			}
		}
	}
}


.map-col {
	.affix {
		@media (max-width:992px) {
			position: relative !important;
		}

		@media (min-width:993px) {
			width: 33.33%;
			max-width: inherit;
			padding-right: 30px;
			top: 60px;
		}

		@media (min-width:1440px) {
			position: fixed !important;
			top: 50px;
			width: 100%;
			max-width: 450px;
		}

	}

	.affix-bottom {
		@media (max-width:992px) {
			position: relative;
			top: inherit !important;
		}
	}
}*/

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		width: 100%;
		padding: 0 15px;

		color: #565a5c;


		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 35px 0 0;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;
			color: #fff;

			h3 {
				/* color: var(--whitecolor);*/
			}

			.sorting-trigger {
				margin-bottom: 30px;

				span {
					/* color: var(--whitecolor);*/

					@media (max-width:767px) {
						display: block;
						margin-bottom: 5px;
					}
				}

				.sorting-item {
					background-color: var(--whitecolor);
					padding: 8px 15px;
					color: var(--greycolor) !important;
					display: inline-block;

					&:hover {
						color: var(--reddarkcolor);
					}

					&.activesorting {
						color: var(--redcolor) !important;
					}


					&.icon-grid {
						&:before {
							content: "\f00a";
							font-family: fontawesome;
							margin-right: 5px;
						}
					}

					&.icon-ascend {
						&:before {
							content: "\f15d";
							font-family: fontawesome;
							margin-right: 5px;
						}
					}

					&.icon-tag {
						&:before {
							content: "\f02b";
							font-family: fontawesome;
							margin-right: 5px;
						}
					}
				}

			}


			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.show-map-list {
		position: absolute;
		margin-top: -35px;
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */
		padding: 25px 0;

		.header {
			display: none;
			padding: 5px;
			background-color: white;

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}



	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			position: relative;

			@media (min-width:993px) {
				&:nth-child(3n+4) {
					clear: both;
				}
			}

			@media (max-width:992px) {
				&:nth-child(2n+3) {
					clear: both;
				}
			}

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: var(--primarycolor);
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}
		}


	}


	.search-result-properties {
		display: flex;
		text-align: center;
		justify-content: center;
		background-color: rgba(0, 51, 102, .8);
		background-color: #222d3b;
		flex-wrap: wrap;
		padding: 5px;
		min-height: 95px;
		align-items: flex-start;

		.ft-icon {
			text-align: center;
			display: inline-flex;
			align-items: center;
			color: #fff;
			border: 1px solid #fff;
			padding: 5px 10px;
			margin: 5px;
			min-height: 32px;

			.ft-title {
				margin-left: 5px;
			}
		}

	}

	.unit-btn {
		.btn.btn-secondary {
			background: var(--reddarkcolor);
			border: none;

			&:hover {
				background: var(--redcolor);
			}
		}
	}



	.search-bar {
		padding: 200px 0 30px 0;

		@media (max-width:991px) {
			padding: 200px 0 50px 0;
		}

		&.search-banner {
			background-image: url(RESOURCE/img/bg-banner1.jpg);
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;

			.form-control {
				margin-bottom: 5px;
				height: 46px;
			}
		}

		label[for="cb_pets"] {
			color: var(--whitecolor);
			font-size: 15px;
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.9);
			padding: 15px;
		}

		.btn {
			font-size: 16px;
			padding: 10px 8px;
			margin: 0 !important;
			height: 46px;

			@media (max-width:1200px) {
				font-size: 14px;
			}

			@media (max-width:991px) {
				font-size: 16px;
			}
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}

		.alert-warning {
			position: absolute;
			top: -60px;

			@media (max-width:1200px) {
				font-size: 12px;
			}

			@media (max-width:991px) {
				bottom: -75px;
				top: auto;
				width: calc(100% - 30px);
				text-align: center;
			}

		}


	}

	.voucher-form {
		@media (max-width:991px) {
			max-width: 100% !important;
		}

	}

	@media (max-width:992px) {

		.list-view {
			.map-pane {
				display: none;
			}

			.result-pane {
				display: block;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}

			.result-pane {
				display: none;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}


	.results {
		.unit-box {
			border: 1px solid var(--whitecolor);
			position: relative;
			margin-bottom: 30px;
			background-color: var(--whitecolor);

			.rent-rate {
				position: absolute;
				top: 60px;
				z-index: 1;
				right: 0;

				figure {
					//background-color: var(--primarycolor);
					background-color: var(--darkbluecolor);
					padding: 5px 10px;
					color: var(--whitecolor);
					font-weight: bold;
				}
			}

			.alternatives {
				padding: 10px;

				.li-bullet {
					list-style: none;
					padding-left: 0;

					a {
						color: var(--secondarycolor2);
					}
				}
			}

			.unit-title {
				color: var(--whitecolor);
				padding: 20px 10px 10px 10px;
				text-align: center;
				background-color: rgba(0, 51, 102, .8);
				background-color: #222d3b;
				/*position: absolute;
				top: 0;*/
				width: 100%;
				z-index: 2;
				margin: 0;

				@media (max-width:767px) {
					font-size: 16px;
				}
			}

			.img-content {
				position: relative;

				.fav-icon {
					position: absolute;
					right: 5px;
					top: 5px;
					color: var(--redcolor);
					font-size: 24px;
					z-index: 1;
				}

				.map-marker {
					position: absolute;

					bottom: 0;
					top: inherit;
					right: 0;

					i {
						color: var(--whitecolor);
					}
				}

				img {
					width: 100%;
				}
			}

			.unit-cont-bl {
				padding: 10px 15px;
				margin-bottom: 0px;


				@media (max-width:767px) {
					height: inherit;
				}

				p {
					font-size: 14px;
					color: var(--secondarycolor2);
					line-height: 20px;
					margin: 0;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 6;
					-webkit-box-orient: vertical;
					overflow: hidden;
					min-height: 120px;
				}
			}

			.unit-feat {
				background-color: var(--primarycolor);


			}
		}
	}


	.map-pane {
		@mixin flex;
		height: 100vh;

		@media (max-width:992px) {
			z-index: 50;
			padding: 0;
		}
	}

	@media (min-width:768px) {


		.result-pane {
			.more-btn-panel {
				display: none;
			}


		}






	}
}

.my-search-token {
	background-color: var(--whitecolor) !important;
	border-radius: 0 !important;
	padding: 2px 8px !important;
	color: var(--blackcolor);
}