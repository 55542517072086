@import "common.css";

.unit-view {

	.title-img-container {
		width: 100%;
		cursor: pointer;
	}

	.single-unit-bl {
		border: 1px solid var(--whitecolor);
	}

	.inner-banner {
		padding: 170px 0 30px;
		position: relative;
		height: 300px;
		overflow: hidden;
		min-height: auto;

		@media (max-width:991px) {
			height: auto;
		}

		.page-header {
			position: relative;
			z-index: 1;
			color: var(--whitecolor);
			margin-top: 0;
			border-bottom: none;

			h1 {
				font-size: 28px;

			}

			p {
				margin-bottom: 15px;
			}
		}

		.cover-img {
			position: absolute;
			top: 0;
			z-index: 0;
			height: 500px;
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 51, 102, .5);
		}
	}

	.unit-list-section {
		.item {
			height: 60vh;
			min-height: 370px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

		}
	}

	.contact-form {
		padding: 15px;
		border: 1px solid var(--greycolor);
		margin-top: 30px;

		h2 {
			margin-top: 10px;
		}
	}

	.info-col {
		&.noaction {
			&.affix {
				@media (min-width:993px) {
					position: fixed;
					top: 100px;
				}
			}
		}

		&.affix {
			position: relative;
		}
	}

	.search-section {
		.searchbox {
			margin: 0 0 12px;

			.form-control {
				color: var(--blackcolor);
			}
		}

		.search-popup-trigger {
			cursor: pointer;
		}

		.form-control:disabled,
		.form-control[readonly] {
			background-color: white;
		}

	}

	.title-img {

		position: relative;
		width: 100%;
		height: 700px;

		-webkit-background-size: cover;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;


		.v-left-gallery-btn,
		.v-right-gallery-btn {
			font-size: 160px;
			position: absolute;
			top: 50%;
			margin-top: -57px;
		}

		.v-left-gallery-btn:hover,
		.v-right-gallery-btn:hover {
			color: #585858;
		}

		.v-right-gallery-btn {
			right: 40px;
		}

		.v-left-gallery-btn {
			left: 40px;
		}

	}



	.panorama {
		position: relative;
		width: 100%;
		height: 700px;
	}



	.main-section {
		position: relative;

		h2 {
			margin-top: 15px;
			font-size: 22px;
			color: var(--defaultcolor);

			.stars {
				display: inline-block;
				margin-left: 22px;
				color: #dfb62d;
			}
		}

		.legend-cal {
			text-align: center;
			padding: 0;
			margin: 15px 0;

			ul {
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				padding: 0;
			}

			li {
				display: inline-flex;
				align-items: center;
				min-height: 30px;
				padding-right: 10px;
				margin: 5px 0;
				text-align: left;

				.day {
					width: 24px;
					height: 24px;
					display: inline-block;
					margin: 0 10px 0 0;
					min-width: 24px;

					&.day-free {
						background: var(--available);
					}

					&.day-full {
						background: var(--not_available);
					}

					&.day-end {
						background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important
					}

					&.day-start {
						background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
					}

					&.day-disable {
						/*background: rgba(246, 237, 216, .6);*/
						background: #bfb397;
					}
				}

				@media all and (min-width:768px) {
					&:last-child {
						padding-left: 75px;
					}
				}


			}
		}

		.contact-form label {
			color: var(--whitecolor) !important;
		}

		.contact-form .guestbox label {
			color: #333 !important;
		}

		.main-col {

			position: static;

			.stars {
				margin-top: 10px;
			}

			.description {
				padding: 15px 0 5px;
			}

			.backlink {
				margin-top: 20px;
			}


			.intro {
				i {
					font-size: 26px;
				}

				>div {
					text-align: center;
				}
			}

			.properties {
				@mixin columnlayout 2;
				margin-bottom: 30px;

				.prop.bool {
					font-weight: bold;
				}

				.prop {
					padding: 0 15px;
					padding-left: 30px;
					position: relative;
					margin-bottom: 12px;

					color: var(--whitecolor) !important;

					&:before {
						content: "\f00c";
						color: var(--secondarycolor);
						position: absolute;
						left: 0;
						font-family: fontawesome;
					}
				}

				.prop.missing {
					text-decoration: line-through;
					font-weight: normal;
				}

				.stars {
					display: inline-block;
					color: #dfb62d;
				}
			}

			.feedbacks {
				margin-top: 30px;

				blockquote {
					div {
						font-family: 'Indie Flower', cursive;
					}

				}
			}


		}

		.info-block-side {
			border: 1px solid var(--whitecolor);

			.price-table {
				td {
					color: var(--whitecolor) !important;
				}
			}


			.info-col-header {
				padding: 0;
				background-color: var(--defaultcolor);

				text-align: center;

				h2 {
					margin-top: 0;
					color: var(--whitecolor);
					font-size: 18px;
					padding: 12px 15px;
					background: var(--reddarkcolor);
				}
			}



			.info-col.noaction {

				/*
				padding: 3px;
				padding-top: 10px;
				position: -webkit-sticky;
				position: sticky;
				top: 10px;
           */



				.info-col-main {
					border-bottom: none;
					width: 100%;
				}

				.subprice {
					width: 100%;
					border: 1px solid #888;
					border-top: none;
				}
			}

		}


		.info-col {

			//max-width: 800px;

			.fav-section {
				padding: 0;
				text-align: right;

				@media all and (min-width:992px) {
					margin-top: -25px;
				}

				.btn {
					background: none;
					color: var(--secondarycolor2);
					text-align: right;
					border-color: var(--secondarycolor2);
					font-size: 12px;
					padding: 3px 15px;
					border-bottom: none;

					.fa {
						color: var(--redcolor);
					}
				}
			}

			@media all and (min-width:992px) {
				&.affix {
					width: 360px;
				}
			}

			.info-col-main {

				padding: 15px 15px 10px 15px;
			}

			.subprice {

				padding: 0 15px;

				.filters {}


				.book-btn {
					margin-top: 30px;
					margin-bottom: 10px;
				}

			}
		}

		.price-list {
			max-width: 400px;

			.subline {
				.labelcol {
					padding-left: 20px;
				}

				td {
					border-top: none;
				}
			}

		}
	}

	.flow-buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.book-btn {
			margin-left: 15px;
		}
	}


	.subsection {
		width: 100%;

		.unit-map {
			height: 400px;
			width: 100%;
		}

		.modal-header {
			position: relative;

			.close {
				float: right;
				position: absolute;
				right: 15px;
				top: 10px;
			}
		}
	}


	.matterport-showcase {
		text-align: center;

		iframe {
			min-height: 240px;
			margin: 16px 0;
			width: 100%;
		}

	}




	@media all and (max-width:767px) {

		.title-img {
			height: 350px;

			.v-left-gallery-btn,
			.v-right-gallery-btn {
				font-size: 80px;
			}

		}

		.panorama {
			height: 350px;
		}




		.main-section {

			.main-col {
				.properties {
					@mixin columnlayout 1;
				}
			}
		}

	}


	@media (min-width:768px) and (max-width:992px) {

		.title-img {
			height: 465px;
		}

		.panorama {
			height: 465px;
		}

		.matterport-showcase iframe {
			min-height: 360px;
		}


	}

	@media (min-width:993px) and (max-width:1199px) {

		.title-img {
			height: 580px;
		}

		.panorama {
			height: 580px;
		}

		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}


	}


	@media screen and (min-width: 993px) {
		.matterport-showcase iframe {
			height: 600px;
			max-width: 1068px;
			margin: 0 auto 15px;

		}

	}

	.voucher-section {
		.alert-warning {
			span {
				color: #000000 !important;
			}

		}

		button {
			height: 46px;

			@media screen and (max-width: 991px) {
				margin-top: 20px;
			}
		}
	}


}


/*
.unit-proposals,
.unit-list-section {

	.single-unit {
		float: left;
		margin: 4px;
		background-color: white;
		padding: 3px;

		a {
			color: inherit;
			text-decoration: none;
		}

		h3 {
			margin-top: 10px;
			font-size: 15px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			width: 224px;
		}

		.udetails {

			padding: 2px;
			opacity: 0.7;
			position: relative;

			.prop {
				padding-right: 4px;
			}


			.door {
				width: 10px;
				height: 15px;
				display: inline-block;
				background: no-repeat url(RESOURCE/img/door-smaller.png) 0 0;
				position: relative;
				top: 2px;
			}

		}
	}
}

*/

.reduced-width-container {
	h2 {
		font-size: 22px;

		color: var(--defaultcolor);
	}
}

.unit-proposals {

	.single-unit {
		margin-bottom: 30px;

		>a {
			display: block;
			position: relative;

			.full-img {
				width: 100%;
			}

			h3 {
				color: var(--whitecolor);
				padding: 10px;
				text-align: center;
				background-color: rgba(0, 51, 102, .8);
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 2;
				font-size: 18px;
				font-weight: 500;
				margin-top: 0;
			}

			.udetails {
				display: flex;
				text-align: center;
				-webkit-box-pack: center;
				justify-content: center;
				background-color: rgba(0, 51, 102, .8);
				flex-wrap: wrap;
				padding: 5px;

				.ft-icon {
					display: inline-flex;
					-webkit-box-align: center;
					align-items: center;
					color: #fff;
					border: 1px solid #fff;
					padding: 5px 10px;
					margin: 5px;

					i {
						margin-right: 5px;
					}
				}
			}
		}
	}
}

.owl-carousel-fs .item {
	height: 400px;
	overflow: hidden;

	img {
		position: relative;
		top: 50%;
		transform: translateY(-50%);
	}

	@media (max-width:767px) {
		height: 200px;
	}
}

.booking-view {
	.service-title {
		.btn {
			display: none;
		}
	}
}

.btn-unit {
	display: none;

	@media (max-width:991px) {
		display: block;
	}

}