@import "common.css";


.inner-banner {
	background-image: url(RESOURCE/img/bg-banner1.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	width: 100%;
	padding: 200px 0 30px;
	position: relative;
	min-height: 700px;

	@media (max-width:1199px) {
		min-height: 580px;
	}

	@media (max-width:991px) {
		min-height: 465px;
	}

	@media (max-width:767px) {
		min-height: 350px;
	}

	&.impressum-banner {
		background-image: url(RESOURCE/img/bg-image-section1.jpg);
	}

	.page-title {
		color: #fff;
		margin-top: 0;
		position: absolute;
		bottom: 50px;

		@media (max-width:450px) {
			font-size: 22px;
		}
	}
}

h4 {
	color: var(--defaultcolor);
}

p {
	margin-bottom: 30px;
}

.tick-li {
	padding-left: 0;

	li {
		padding: 0 15px 0 30px;
		position: relative;
		margin-bottom: 8px;
		font-size: 16px;
		list-style: none;
		color: var(--whitecolor);

		&:before {
			content: "\f00c";
			color: #dc143c;
			color: var(--secondarycolor);
			position: absolute;
			left: 0;
			font-family: fontawesome;
		}
	}
}

.facility-page-block {
	color: var(--whitecolor);

	h2 {
		color: var(--whitecolor) !important;
	}
}


.faq-v1 {
	.ul-list {
		color: #fff;
		padding-left: 20px;
	}
}

.white-text p,
.white-text span,
.white-text h1,
.white-text h2,
.white-text h3,
.white-text h4,
.white-text h5 {
	color: var(--whitecolor) !important;
}