@import "setup.css";

.indexpage {


	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.search-link {
		position: absolute;
		bottom: -49px;
		display: block;
		text-align: center;
		width: 100%;
		left: 0;

		a {
			color: var(--whitecolor);
			text-decoration: underline;
			margin-top: 5px;
		}
	}

	.iheader {

		min-height: 700px;
		height: 100vh;
		position: relative;



		.intro {
			position: relative;
			z-index: 3;

			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 30px;
				padding-bottom: 0px;
				padding-top: 20vh;

				@media (max-width:1024px) {
					padding-top: 20vh;
				}

				@media (max-width:992px) {
					padding-top: 28vh;
				}

				@media (max-width:767px) {
					padding-top: 23vh;
				}

				@media (max-width:767px) {
					padding-top: 19vh;
				}

				.logo-inline {
					@media (max-width:992px) {
						max-width: 400px;

					}

					@media (max-width:550px) {
						max-width: 350px;
					}

					max-width: 500px;
					display: inline-block;
				}


				h1 {
					font-size: 34px;
					font-weight: 400;
					margin: 30px 0 0;
					text-shadow: 0 0 10px #000;
					border-top: 1px solid var(--whitecolor);
					display: inline-block;
					padding-top: 15px;
					color: var(--whitecolor);

					@media (max-width:992px) {
						font-size: 24px;
					}

					@media (max-width:550px) {
						font-size: 19px;
					}

				}

				h2 {
					font-size: 24px;
					font-weight: 700;
					margin: 20px 0 0;

				}

			}

		}


	}

	.banner-slide {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		z-index: -1;

		.owl-dots,
		.owl-nav {
			display: none;
		}

		.owl-item {
			position: relative;

			&:after {
				background-color: rgba(0, 0, 0, .35);
				content: "";
				width: 100%;
				height: 100%;
				position: absolute;
				left: 0;
				top: 0;
				z-index: 0;
			}
		}

		.item {
			height: 100vh;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
			/*padding: 0 15px;*/
			width: 100%;
			justify-content: center;
			min-height: 700px;

			&.slide1 {
				background-image: url(RESOURCE/img/bg-banner1.jpg);
			}

			&.slide2 {
				background-image: url(RESOURCE/img/bg-banner2.jpg);
			}

			&.slide3 {
				background-image: url(RESOURCE/img/bg-banner3.jpg);
			}

			&.slide4 {
				background-image: url(RESOURCE/img/bg-banner4.jpg);
				background-position: 85%;
			}

			&.slide5 {
				background-image: url(RESOURCE/img/bg-banner5.jpg);
			}

		}
	}

	.search-link {
		position: absolute;
		bottom: -49px;
		display: block;
		text-align: center;
		width: 100%;
		left: 0;
	}


	.searchbar {
		position: absolute;
		background: rgba(255, 255, 255, 0.35);
		width: 100%;
		bottom: 50px;
		padding-top: 20px;
		padding-bottom: 20px;
		max-width: inherit !important;

		.row {
			margin: 0;
		}

		.searchbox {
			padding: 5px;

			.form-control {
				height: 40px;
			}

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

		@media (min-width:768px) {
			max-width: 650px;
		}

		@media (min-width:993px) {
			max-width: 950px;
		}

	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;
			height: 100vh;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}

	.logo-image-bl {
		padding: 30px;
		text-align: center;
		display: block;
	}

	.contact-form {
		.btn.btn-go {
			background: var(--redcolor);
			border-color: var(--redcolor);

			&:hover {
				background: var(--reddarkcolor);
				border-color: var(--reddarkcolor);
			}
		}
	}
}