@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.flex {
	@mixin flex;
}

@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

.checks {
	.checkbox {
		a {
			position: relative;
			top: 2px;
		}
	}
}


.dlg {

	.body,
	.header {

		p,
		h2,
		h3,
		h4,
		h1,
		span,
		li {
			color: var(--blackcolor) !important;
		}
	}
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.alert {
	&.alert-info {
		color: #fff;
		background-color: #6696BD;
		border-color: #6696BD;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

body {
	.asd__mobile-header {
		h3 {
			color: #333 !important;
		}
	}
}

.datepicker-trigger {


	.asd__day--not-available {
		background: var(--not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(135deg, var(--available) 50%, var(--not_available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--available) 50%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(135deg, var(--disabled) 50%, var(--not_available) 50%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(135deg, var(--not_available) 50%, var(--disabled) 50%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--in-range {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--enabled.asd__day--hovered {
		background: var(--selection_range) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: var(--text_color) !important;
	}

	.asd__day--enabled {
		background-color: var(--available) !important;
		color: var(--text_color) !important;

		button {
			text-decoration: underline;
		}

		&:hover {
			background-color: var(--selected) !important;
		}



		&.asd__day--disabled {
			background-color: var(--disabled) !important;
			color: var(--text_color);
			text-decoration: underline;

			&.asd__day--selected {
				background-color: var(--selected) !important;
			}

			&.asd__day--in-range {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}

			&.asd__day--hovered {
				background: var(--selection_range) !important;
				opacity: 1 !important;
				border: 1px double var(--selected_border) !important;
			}
		}

		&.asd__day--arrival {
			&:hover {
				background-color: var(--selected) !important;
			}
		}

		&.asd__day--not-available {
			background: var(--not_available) !important;
			opacity: 1 !important;
		}
	}

	.asd__day--selected {
		background: var(--selected) !important;
		color: var(--text_color) !important;
		opacity: 1 !important;
		border: 1px double var(--selected_border) !important;

		/*Arrow display css start*/
		&.asd__selected-date-one {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-left: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				right: 0px;
			}
		}

		&.asd__selected-date-two {
			position: relative;

			&:before {
				content: "";
				position: absolute;
				border-right: 12px solid transparent;
				border-top: 19px solid var(--selection_range);
				border-bottom: 20px solid var(--selection_range);
				left: 0px;
			}
		}

		/*Arrow display css end*/
	}
}

.asd__inner-wrapper {
	.asd__month-name {
		span {
			color: var(--blackcolor) !important;
		}
	}

}


body {
	font-family: var(--d-font);
}

/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

.txt-prim {
	color: var(--primary);
}

.pad60 {
	padding: 60px 0;

	@media (max-width:450px) {
		padding: 30px 0;
	}
}

.mb-30 {
	margin-bottom: 30px;
}

a:focus,
.btn:focus {
	outline: 0;
	text-decoration: none !important;
}

.modal {
	a {
		word-break: break-all;
	}

	.close {
		position: absolute;
		right: 10px;
		top: 10px;
		font-size: 34px;
	}

	.modal-body {
		height: calc(100vh - 200px);
		overflow-y: scroll;
		overflow-x: hidden;
	}


	.modal-content {
		border-radius: 0;
	}

	.modal-dialog {
		&.modal-lg {
			max-width: 800px;
		}
	}
}

.btn {
	border-radius: 0;
	font-size: 16px;
	padding: 8px 16px;

	@media (max-width:1200px) {
		font-size: 14px;
	}

	&.btn-primary,
	&.btn-go {
		//background-color: var(--primarycolor);
		//color: var(--whitecolor);
		//border-color: var(--primarycolor);
		background-color: var(--reddarkcolor);
		border-color: var(--reddarkcolor);
		color: var(--whitecolor);

		&:hover {
			//background-color: var(--secondarycolor);
			//border-color: var(--secondarycolor);
			background-color: var(--redcolor);
			color: var(--whitecolor);
			border-color: var(--redcolor);
		}
	}

	&.btn-sm {
		font-size: 14px;
		padding: 6px 12px;
	}

	&.btn-secondary {
		//background-color: var(--secondarycolor);
		//border-color: var(--secondarycolor);
		background-color: var(--reddarkcolor);
		border-color: var(--reddarkcolor);
		color: var(--whitecolor);

		&:hover {
			//background-color: var(--primarycolor);
			//border-color: var(--primarycolor);
			background-color: var(--redcolor);
			color: var(--whitecolor);
			border-color: var(--redcolor);

		}
	}

	&.btn-lg {
		height: 46px;
		font-size: 18px;
		padding: 10px 16px;
	}
}

.mt-30 {
	margin-top: 30px;
}

.price {
	color: var(--secondarycolor);
}

h1,
h2,
h3,
h4 {
	text-transform: uppercase;
}

h1,
h2,
h3 {
	font-weight: 700;
}

h1,
h2 {
	font-size: 28px;
}

.list-block {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: block;
		padding: 3px 0;

		a {
			color: var(--blackcolor);
		}
	}
}

.list-icon {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		display: block;
		padding: 3px 0;
		padding-left: 25px;
		position: relative;

		i {
			color: var(--greycolor);
			position: absolute;
			left: 0;
			top: 7px;
		}

		a {
			color: var(--blackcolor);
		}
	}
}

.asd__wrapper--full-screen {
	z-index: 10 !important;
}

.container {
	&.medium {
		max-width: 992px;
	}
}

p {
	font-size: 16px;
	line-height: 24px;
}

.form-horizontal {
	.control-label {
		text-align: left;
	}
}

.form-control {
	border-radius: 0;
	border: 1px solid #dfdfdf;
	background-color: #fcfcfc;
}

a {
	color: var(--secondarycolor);

	&:hover {
		color: var(--primarycolor);
		text-decoration: none;
	}
}

a:focus,
a,
input,
button:focus,
.btn,
input:focus,
.checkbox input[type=checkbox]:focus+label:before,
label:focus,
.btn:focus {
	outline: 0 !important;
	text-decoration: none !important;
}

.guestbox label {
	color: var(--blackcolor);
}

.bg-dark {
	background-color: #8e9ca5;
}

.d-flex {
	display: flex;
	flex-wrap: wrap;
}

.text-white {
	color: #fff;
}

.flex-row-reverse {
	@media (min-width:992px) {
		display: flex;
		flex-direction: row-reverse;
	}
}

.bg-dark-blue2 {
	background-color: var(--darkbluecolor);
	color: var(--whitecolor);
}

.pt-0 {
	padding-top: 0 !important;
}

.checkbox-success input[type=checkbox]:checked+label:before {
	background-color: var(--secondarycolor) !important;
	border-color: var(--secondarycolor) !important;
}

.box {
	border-radius: 0 !important;
}

.center-text {
	text-align: center;
}

.doc-box {
	.footer-link {
		vertical-align: top;
		margin-top: 1px;
		display: inline-block;
	}
}



.owl-nav {

	button {
		width: 32px;
		height: 32px;
		text-align: center;
		position: absolute;
		top: 50%;
		background: rgba(255, 255, 255, 0.5) !important;
		border-radius: 4px !important;
		transition: all ease 0.3s;

		&:hover {
			background: rgba(255, 255, 255, 1) !important;
		}

		&:before {
			font-size: 24px;
			color: var(--secondarycolor2);
			font-family: 'Font Awesome 5 Pro';
			font-weight: 900;
		}

		span {
			display: none;
		}

		&.owl-prev {
			left: 20px;
			margin: -22px 0 0 !important;

			&:before {
				content: "\f104";
			}
		}

		&.owl-next {
			right: 20px;
			margin: -22px 0 0 !important;

			&:before {
				content: "\f105";
			}
		}
	}
}

.owl-thumbs {

	@media all and (max-width:767px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.owl-thumb-item {
		border: none;
		outline: 0 none;
		margin: 0;
		padding: 0;
		float: left;
		margin: 5px 5px 5px 0;

		@media all and (max-width:767px) {
			padding: 5px 5px 0 !important;
			margin: 0;
			width: 20%;
			overflow: hidden;
		}

		&.active {
			img {
				opacity: 0.5 !important;
			}
		}
	}



	.userimg {
		width: 68px;
		height: 68px;
		overflow: hidden;
		display: inline-block;
		vertical-align: top;
		position: relative;

		@media all and (max-width:767px) {
			width: 100%;
		}

		img {
			transform: translate(-50%, -50%);
			position: absolute;
			left: 50%;
			top: 50%;
		}
	}
}

.hide-mob {
	display: block;

	@media (max-width:992px) {
		display: none;
	}
}

.show-mob {
	display: none;

	@media (max-width:992px) {
		display: block;
	}
}

.payment-view {
	padding-top: 130px;
	padding-bottom: 60px;
}

.accordion {
	.accordion-item {
		border: 1px solid #acb7c5;

		&:first-of-type {
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		.accordion-button {
			border: 0;
			padding: 1rem;
			width: 100%;
			text-align: left;
			position: relative;


		}

		.accordion-button[aria-expanded="true"] {
			&:before {
				content: "";
				border: solid #000;
				border-width: 0 1px 1px 0;
				display: inline-block;
				padding: 5px;
				transform: rotate(-135deg);
				-webkit-transform: rotate(-135deg);
				position: absolute;
				right: 14px;
				top: 16px;
			}
		}

		.accordion-button[aria-expanded="false"] {
			&:before {
				content: "";
				border: solid #000;
				border-width: 0 1px 1px 0;
				display: inline-block;
				padding: 5px;
				transform: rotate(45deg);
				-webkit-transform: rotate(45deg);
				position: absolute;
				right: 14px;
			}
		}

		.accordion-body {
			padding: 1rem;
		}
	}
}

.mob-hide {
	@media (max-width:767px) {
		display: none;
	}
}

.desk-hide {
	display: none;

	@media (max-width:767px) {
		display: block;
	}
}