@import "setup.css";

.navbarheader {
	position: fixed;
	z-index: 6;
	width: 100%;
	background: rgba(255, 255, 255, .8);
	padding: 10px 0px;
	top: 0;

	&.affix {
		background-color: var(--whitecolor);
		margin-top: 0;
		box-shadow: 0 0px 4px rgba(0, 0, 0, .1);


		.logo {
			max-width: 60px;

			@media (max-width: 374px) {
				max-width: 45px;
			}
		}

		nav {
			height: 45px;

			@media (max-width: 992px) {
				height: 60px;
			}
		}

		.nav-contact {
			@media (max-width: 992px) {
				top: 9px;
			}

		}
	}

	.nav-contact {

		.phone {
			display: flex;
			align-items: center;

			@media (max-width:992px) {
				span {
					display: none;
				}
			}
		}
	}


	.logo {
		max-width: 60px;
		display: block;

		@media (max-width:374px) {
			max-width: 45px;
		}
	}



	nav {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: relative;
		height: 60px;

		@media (max-width:992px) {}

		.navbar-area {
			@media (min-width:993px) {
				display: flex;
				align-items: center;
			}

			@media (max-width:992px) {
				position: absolute;
				display: none;
				width: 100%;
				background-color: #fff;
				left: 0;
			}

			padding: 0;
			margin: 0;

			li {
				list-style: none;

				a {
					/*color: var(--secondarycolor);*/
					color: var(--secondarycolor2);

					&:hover {
						text-decoration: none;
					}
				}
			}
		}
	}


	.nav-contact {
		display: flex;
		align-items: center;

		a {

			@media (max-width:992px) {
				margin-left: 5px;
			}
		}

		.fa {
			padding-top: 6px;
			padding-left: 7px;
			font-size: 13px;
			width: 27px;
			height: 27px;
			/*background: var(--secondarycolor);*/
			background: var(--secondarycolor2);
			color: #fff;
			border-radius: 100%;
			margin: 0 8px;

			@media (max-width:479px) {
				margin: 0 2px;
			}
		}

		.header-search {
			max-width: 238px;
			padding-left: 20px;

			@media (max-width:479px) {
				padding-left: 8px;
			}

			.search {
				.form-control {
					height: 40px;
					width: 180px;
					transition: width .3s ease-in-out;
					-webkit-transition: width .3s ease-in-out;
					-moz-transition: width .3s ease-in-out;
					-ms-transition: width .3s ease-in-out;
					border-radius: 0;
					box-shadow: none;
					padding-left: 20px;
					padding-right: 6px;
					border-radius: 30px 0 0 30px;

					@media (max-width:767px) {
						width: 130px;

						&:focus {
							width: 150px
						}
					}

					@media (max-width:479px) {
						width: 110px;
						font-size: 12px;
						padding-left: 10px;

						&:focus {
							width: 115px
						}
					}

					@media (max-width:374px) {
						width: 85px;
						font-size: 9px;

						&:focus {
							width: 85px
						}

					}
				}

				.btn {
					border-radius: 0;
					border: none;
					padding: 0;
					width: 40px;
					height: 40px;
					background: var(--secondarycolor2);
					color: #fff;
					font-size: 17px;
					border-radius: 0 30px 30px 0;

					.fa {
						padding: 0;
						font-size: 16px;
						margin: 10px 0;
						height: auto;
					}
				}
			}
		}


		/*span {
			padding: 17px 2px;
			color: var(--blackcolor);
			font-size: 14px;

			@media (max-width:1240px) {
				font-size: 13px;
			}

			@media (max-width:1170px) {
				display: none;
			}
		}*/


		@media (max-width:992px) {
			position: absolute;
			top: 10px;
			right: 37px;
		}
	}
}


.vo-nav-bar {

	a.nav-item {
		font-size: 14px;
		font-weight: 500;
		letter-spacing: .05em;
		text-transform: uppercase;
		padding: 20px 10px;

		@media (max-width:1240px) {
			font-size: 13px;
			padding: 20px 4px;
		}

		@media (max-width:1170px) {
			padding: 20px 10px;
		}

		@media (max-width:992px) {
			padding: 10px;
			font-size: 14px;
		}

		&:hover {
			color: var(--secondarycolor);
			text-decoration: none;
		}
	}


	.icon {
		display: none;
	}

	.vo-dropdown,
	.input-group {
		float: left;
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}


	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
		z-index: 1;
	}

	.vo-dropdown-content a {
		float: none;
		color: black;
		padding: 12px 16px;
		text-decoration: none;
		display: block;
		text-align: left;
	}



	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
			position: relative;
			color: #fff;
			color: var(--whitecolor);
			background-color: #dc143c;
			background-color: var(--secondarycolor2);
			border-radius: 50%;
			width: 27px;
			height: 27px;
			text-align: center !important;
			margin-left: 15px;
			line-height: 27px;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}

		.rightme {
			float: right;
		}

	}



}


@media screen and (max-width: var(--navbarbreak)) {


	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .navbar-area {
		@media (max-width:992px) {
			display: block;
			box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
			top: 100%;
		}
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}
}